<template>
  <div>
    <div class="input-container">
      <div
        v-if="
          ((institution && institution.internal_use_id == 'duoc_uc') ||
            $debug_change_duoc) &&
          TimeAllocation &&
          TimeAllocation.internal_use_id == 'duoc_autonomous_work_hours'
        "
        :id="`input-weighing-${OldMatter.id}-${TimeAllocation.id}`"
        class="mr-1"
      >
        {{ automatic_chronological_hours }}
      </div>
      <div>
        <template v-if="view_total_hours">
          <b-form-input
            v-model="matter_time_allocation.hours"
            @update="
              (value) => {
                if (parseFloat(value) < 0 || value == null || value == '') {
                  matter_time_allocation.hours = 0;
                  patchMatterTimeAllocation();
                } else {
                  matter_time_allocation.hours = value;
                  patchMatterTimeAllocation();
                }
              }
            "
            size="sm"
          >
          </b-form-input>
        </template>
        <template v-else-if="!view_total_hours">
          <b-form-input
            v-model="matter_time_allocation.weekly_hour"
            @update="
              (value) => {
                if (parseFloat(value) < 0 || value == null || value == '') {
                  matter_time_allocation.weekly_hour = 0;
                  matter_time_allocation.hours = 0;
                  patchMatterTimeAllocation();
                } else {
                  matter_time_allocation.weekly_hour = value;
                  patchMatterTimeAllocation();
                }
              }
            "
            size="sm"
          >
          </b-form-input>
        </template>
      </div>
      <div>
        <b-form-checkbox
          class="ml-1 mr-0"
          size="md"
          v-model="matter_time_allocation.counts_towards_credits"
          @input="patchMatterTimeAllocation()"
        ></b-form-checkbox>
      </div>
    </div>
    <b-popover
      v-if="
        ((institution && institution.internal_use_id == 'duoc_uc') ||
          $debug_change_duoc) &&
        TimeAllocation &&
        TimeAllocation.internal_use_id == 'duoc_autonomous_work_hours'
      "
      :target="`input-weighing-${OldMatter.id}-${TimeAllocation.id}`"
      placement="top"
      triggers="hover focus"
    >
      <!-- triggers="click" -->
      <template #title>{{ TimeAllocation.name }} Automáticos</template>
      <div>
        <div>
          <strong>
            {{
              $getVisibleNames(
                "manual.total_pedagogical_hours_to_chronological",
                true,
                "Total de Horas Pedagógicas (En Cronológicas)"
              )
            }}
          </strong>
          <b-input-group append="%" size="sm" class="input-hours">
            <b-form-input
              class="mt-0 input-pedagogical"
              v-model="pedagogical_weighing"
              min="1"
              max="100"
              disabled
            ></b-form-input>
          </b-input-group>
        </div>
        <div>
          <strong>Horas Cronológicas:</strong>

          <b-input-group append="%" size="sm" class="input-hours">
            <b-form-input
              :key="key_chronological_weighing"
              class="mt-0 input-chronological"
              v-model="chronological_weighing"
              type="number"
              :disabled="OldMatter.is_closed"
              min="0"
              max="99"
              @input="changeChronological"
            ></b-form-input>
          </b-input-group>
        </div>
        <div>
          <div
            v-if="
              ((institution && institution.internal_use_id == 'duoc_uc') ||
                $debug_change_duoc) &&
              TimeAllocation &&
              TimeAllocation.internal_use_id == 'duoc_autonomous_work_hours'
            "
            class="container-btn-popover"
          >
            <b-button
              variant="primary"
              size="sm"
              class="mt-2"
              v-b-tooltip.v-secondary.noninteractive="
                `Copiar la información de la ${TimeAllocation.name} automático`
              "
              @click="copyChronologicalHours"
            >
              Copiar Hora Automática
              <!-- <b-icon font-scale="1.5" icon="clipboard-check"></b-icon> -->
            </b-button>
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { generateUniqueId, toast } from "@/utils/utils";
import { mapGetters } from "vuex";
export default {
  name: "InputMTimeAllocation",
  components: {},
  props: {
    MatterTimeAllocation: {
      default: function () {
        return {
          id: generateUniqueId(),
          matter: null,
          time_allocation: null,
          counts_towards_credits: false,
          attendance_requirement: 0,
          hours: 0,
          weekly_hour: 0,
        };
      },
    },
    TimeAllocation: {
      type: Object,
      required: true,
    },
    OldMatter: {
      type: Object,
      required: true,
    },
    totalPedagogicalChronological: {
      type: Number,
      default: 0,
    },
    view_total_hours: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      matter_time_allocation: { ...this.MatterTimeAllocation },
      key_chronological_weighing: 0,
      pedagogical_weighing: 60,
      chronological_weighing: 40,
      automatic_chronological_hours: 0,
      check_weighing: true,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
    }),
  },
  methods: {
    copyChronologicalHours() {
      if (
        parseFloat(this.automatic_chronological_hours) < 0 ||
        this.automatic_chronological_hours == null ||
        this.automatic_chronological_hours == ""
      ) {
        this.matter_time_allocation.hours = 0;
      } else
        this.matter_time_allocation.hours = this.automatic_chronological_hours;
    },
    changeChronological(chronological_value) {
      if (parseFloat(chronological_value) > 100) {
        this.chronological_weighing = 99;
        this.key_chronological_weighing++;
      } else if (parseFloat(chronological_value) < 0) {
        this.chronological_weighing = 0;
        this.key_chronological_weighing++;
      } else if (
        parseFloat(chronological_value) > 0 &&
        parseFloat(chronological_value) < 100
      ) {
        this.automatic_chronological_hours = Math.round(
          (parseFloat(chronological_value) *
            this.totalPedagogicalChronological) /
            (100 - parseFloat(chronological_value))
        );
      }
    },
    patchMatterTimeAllocation() {
      if (this.timeout) clearTimeout(this.timeout);
      if (
        !this.view_total_hours &&
        this.OldMatter &&
        this.OldMatter.weeks > 0 &&
        this.matter_time_allocation.weekly_hour >= 0
      ) {
        this.matter_time_allocation.hours = Math.round(
          parseFloat(this.matter_time_allocation.weekly_hour) *
            parseFloat(this.OldMatter.weeks)
        );
      } else if (!this.view_total_hours) return;
      if (
        parseFloat(this.matter_time_allocation.hours) < 0 ||
        this.matter_time_allocation.hours == null ||
        (this.matter_time_allocation.hours == "" && this.view_total_hours)
      )
        return;
      else if (this.matter_time_allocation.hours >= 0) {
        this.timeout = setTimeout(() => {
          this.matter_time_allocation.matter = this.OldMatter.id;
          this.matter_time_allocation.time_allocation = this.TimeAllocation.id;
          this.chronological_weighing = Math.round(
            (parseFloat(this.matter_time_allocation.hours) /
              (parseFloat(this.matter_time_allocation.hours) +
                parseFloat(this.totalPedagogicalChronological))) *
              100
          );
          this.automatic_chronological_hours = Math.round(
            (parseFloat(this.chronological_weighing) *
              parseFloat(this.totalPedagogicalChronological)) /
              (100 - parseFloat(this.chronological_weighing))
          );
          if (isNaN(this.matter_time_allocation.id))
            this.createMatterTimeAllocation();
          else this.updateMatterTimeAllocation();
        }, 500);
      }
    },
    createMatterTimeAllocation() {
      this.matter_time_allocation.counts_towards_credits =
        this.TimeAllocation.counts_towards_credits;
      this.matter_time_allocation.attendance_requirement =
        this.TimeAllocation.attendance_requirement;
      this.$store
        .dispatch(
          names.POST_MATTER_TIME_ALLOCATION,
          this.matter_time_allocation
        )
        .then((response) => {
          this.matter_time_allocation = response;
          if (this.OldMatter && this.OldMatter.weeks > 0)
            this.matter_time_allocation.weekly_hour = parseFloat(
              (response.hours / parseFloat(this.OldMatter.weeks)).toFixed(2)
            );
          else this.matter_time_allocation.weekly_hour = 0;
          toast("Cambio realizado");
          this.$emit("changed_hour");
        });
    },
    updateMatterTimeAllocation() {
      this.$store
        .dispatch(
          names.UPDATE_MATTER_TIME_ALLOCATION,
          this.matter_time_allocation
        )
        .then((response) => {
          this.matter_time_allocation = response;
          if (this.OldMatter && this.OldMatter.weeks > 0)
            this.matter_time_allocation.weekly_hour = parseFloat(
              (response.hours / parseFloat(this.OldMatter.weeks)).toFixed(2)
            );
          else this.matter_time_allocation.weekly_hour = 0;
          toast("Cambio realizado");
          this.$emit("changed_hour");
        });
    },
  },
  mounted() {},
  watch: {
    totalPedagogicalChronological(total_pedagogical) {
      if (
        ((this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc) &&
        this.TimeAllocation &&
        this.TimeAllocation.internal_use_id == "duoc_autonomous_work_hours"
      ) {
        if (parseFloat(total_pedagogical) > 0) {
          if (!this.check_weighing) {
            this.automatic_chronological_hours = Math.round(
              (parseFloat(this.chronological_weighing) *
                parseFloat(total_pedagogical)) /
                (100 - parseFloat(this.chronological_weighing))
            );
          }
        }
      }
    },
    chronological_weighing(new_value) {
      if (parseFloat(new_value) >= 0 && parseFloat(new_value) < 100) {
        this.pedagogical_weighing = 100 - parseFloat(new_value);
      } else if (new_value == "") this.pedagogical_weighing = 100;
    },
  },
  created() {
    const matter_time_allocation = this.matter_time_allocations.find(
      (x) =>
        x.time_allocation == this.TimeAllocation.id &&
        x.matter == this.OldMatter.id
    );
    if (matter_time_allocation && this.OldMatter) {
      this.matter_time_allocation = matter_time_allocation;
      if (this.OldMatter.weeks > 0)
        this.matter_time_allocation.weekly_hour = parseFloat(
          (
            matter_time_allocation.hours / parseFloat(this.OldMatter.weeks)
          ).toFixed(2)
        );
      else this.matter_time_allocation.weekly_hour = 0;
    }
    // para cambiar la ponderación al ingresar la vista y el automatic_chronological_hours.
    if (
      this.check_weighing &&
      this.totalPedagogicalChronological > 0 &&
      ((this.institution && this.institution.internal_use_id == "duoc_uc") ||
        this.$debug_change_duoc) &&
      this.TimeAllocation &&
      this.TimeAllocation.internal_use_id == "duoc_autonomous_work_hours"
    ) {
      this.chronological_weighing = Math.round(
        (parseFloat(matter_time_allocation.hours) /
          (parseFloat(matter_time_allocation.hours) +
            parseFloat(this.totalPedagogicalChronological))) *
          100
      );
      this.automatic_chronological_hours = Math.round(
        (parseFloat(this.chronological_weighing) *
          parseFloat(this.totalPedagogicalChronological)) /
          (100 - parseFloat(this.chronological_weighing))
      );
      this.check_weighing = false;
    }
  },
};
</script>

<style scoped>
.input-hours {
  display: flex;
  justify-content: flex-end;
}
.container-btn-popover {
  display: flex;
  justify-content: center;
}
.input-chronological,
.input-pedagogical {
  min-width: 70px;
}
input {
  padding: 1px 1px 1px 5px;
  height: 30px;
  max-width: 40px;
  font-size: var(--secondary-font-size);
}
.input-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.btn-select-default {
  border: none;
  background: transparent;
  font-size: 11pt;
  padding: 0;
  margin-left: 0.7rem;
}
</style>