var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"input-container"},[(
        ((_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
          _vm.$debug_change_duoc) &&
        _vm.TimeAllocation &&
        _vm.TimeAllocation.internal_use_id == 'duoc_autonomous_work_hours'
      )?_c('div',{staticClass:"mr-1",attrs:{"id":`input-weighing-${_vm.OldMatter.id}-${_vm.TimeAllocation.id}`}},[_vm._v(" "+_vm._s(_vm.automatic_chronological_hours)+" ")]):_vm._e(),_c('div',[(_vm.view_total_hours)?[_c('b-form-input',{attrs:{"size":"sm"},on:{"update":(value) => {
              if (parseFloat(value) < 0 || value == null || value == '') {
                _vm.matter_time_allocation.hours = 0;
                _vm.patchMatterTimeAllocation();
              } else {
                _vm.matter_time_allocation.hours = value;
                _vm.patchMatterTimeAllocation();
              }
            }},model:{value:(_vm.matter_time_allocation.hours),callback:function ($$v) {_vm.$set(_vm.matter_time_allocation, "hours", $$v)},expression:"matter_time_allocation.hours"}})]:(!_vm.view_total_hours)?[_c('b-form-input',{attrs:{"size":"sm"},on:{"update":(value) => {
              if (parseFloat(value) < 0 || value == null || value == '') {
                _vm.matter_time_allocation.weekly_hour = 0;
                _vm.matter_time_allocation.hours = 0;
                _vm.patchMatterTimeAllocation();
              } else {
                _vm.matter_time_allocation.weekly_hour = value;
                _vm.patchMatterTimeAllocation();
              }
            }},model:{value:(_vm.matter_time_allocation.weekly_hour),callback:function ($$v) {_vm.$set(_vm.matter_time_allocation, "weekly_hour", $$v)},expression:"matter_time_allocation.weekly_hour"}})]:_vm._e()],2),_c('div',[_c('b-form-checkbox',{staticClass:"ml-1 mr-0",attrs:{"size":"md"},on:{"input":function($event){return _vm.patchMatterTimeAllocation()}},model:{value:(_vm.matter_time_allocation.counts_towards_credits),callback:function ($$v) {_vm.$set(_vm.matter_time_allocation, "counts_towards_credits", $$v)},expression:"matter_time_allocation.counts_towards_credits"}})],1)]),(
      ((_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
        _vm.$debug_change_duoc) &&
      _vm.TimeAllocation &&
      _vm.TimeAllocation.internal_use_id == 'duoc_autonomous_work_hours'
    )?_c('b-popover',{attrs:{"target":`input-weighing-${_vm.OldMatter.id}-${_vm.TimeAllocation.id}`,"placement":"top","triggers":"hover focus"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.TimeAllocation.name)+" Automáticos")]},proxy:true}],null,false,1783653078)},[_c('div',[_c('div',[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours_to_chronological", true, "Total de Horas Pedagógicas (En Cronológicas)" ))+" ")]),_c('b-input-group',{staticClass:"input-hours",attrs:{"append":"%","size":"sm"}},[_c('b-form-input',{staticClass:"mt-0 input-pedagogical",attrs:{"min":"1","max":"100","disabled":""},model:{value:(_vm.pedagogical_weighing),callback:function ($$v) {_vm.pedagogical_weighing=$$v},expression:"pedagogical_weighing"}})],1)],1),_c('div',[_c('strong',[_vm._v("Horas Cronológicas:")]),_c('b-input-group',{staticClass:"input-hours",attrs:{"append":"%","size":"sm"}},[_c('b-form-input',{key:_vm.key_chronological_weighing,staticClass:"mt-0 input-chronological",attrs:{"type":"number","disabled":_vm.OldMatter.is_closed,"min":"0","max":"99"},on:{"input":_vm.changeChronological},model:{value:(_vm.chronological_weighing),callback:function ($$v) {_vm.chronological_weighing=$$v},expression:"chronological_weighing"}})],1)],1),_c('div',[(
            ((_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc) &&
            _vm.TimeAllocation &&
            _vm.TimeAllocation.internal_use_id == 'duoc_autonomous_work_hours'
          )?_c('div',{staticClass:"container-btn-popover"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `Copiar la información de la ${_vm.TimeAllocation.name} automático`
            ),expression:"\n              `Copiar la información de la ${TimeAllocation.name} automático`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"mt-2",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.copyChronologicalHours}},[_vm._v(" Copiar Hora Automática ")])],1):_vm._e()])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }